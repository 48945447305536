import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const DEFAULT_MESSAGE_FOR_SUCCESS = 'Action completed succesfuly!';
const DEFAULT_TITLE_FOR_SUCCESS = 'SUCCESS';
const DEFAULT_MESSAGE_FOR_ERROR = 'Something went wrong!';
const DEFAULT_TITLE_FOR_ERROR = 'ERROR';
const DEFAULT_TITLE_FOR_INFO = 'INFO';
const DEFAULT_TITLE_FOR_WARNING = 'WARNING';
const DEFAULT_TIME_OUT = 5000;

@Injectable({
    providedIn: 'root'
})
export class CustomToastrService {
    private constructor(
        private toastr: ToastrService
    ) {
    }

    success(custom?: { message?: string, title?: string, timeOut?: number }): void {
        let toastObject: any = {};
        toastObject.message = custom && custom.message ? custom.message : DEFAULT_MESSAGE_FOR_SUCCESS;
        toastObject.title = custom && custom.title ? custom.title : DEFAULT_TITLE_FOR_SUCCESS;
        toastObject.timeOut = custom && custom.timeOut ? custom.timeOut : DEFAULT_TIME_OUT;
        this.toastr.success(toastObject.message, toastObject.title, {
            timeOut: toastObject.timeOut
        });
    }

    err(custom?: { message?: string, title?: string, timeOut?: number }): void {
        let toastObject: any = {};
        toastObject.message = custom && custom.message ? custom.message : DEFAULT_MESSAGE_FOR_ERROR;
        toastObject.title = custom && custom.title ? custom.title : DEFAULT_TITLE_FOR_ERROR;
        toastObject.timeOut = custom && custom.timeOut ? custom.timeOut : DEFAULT_TIME_OUT;
        this.toastr.error(toastObject.message, toastObject.title, {
            timeOut: toastObject.timeOut
        });
    }

    info(custom: { message: string, title?: string, timeOut?: string }): void {
        let toastObject: any = {};
        toastObject.message = custom.message;
        toastObject.title = custom && custom.title ? custom.title : DEFAULT_TITLE_FOR_INFO;
        toastObject.timeOut = custom && custom.timeOut ? custom.timeOut : DEFAULT_TIME_OUT;
        this.toastr.info(toastObject.message, toastObject.title, {
            timeOut: toastObject.timeOut
        });
    }

    warning(custom: { message: string, title?: string, timeOut?: any }): void {
        let toastObject: any = {};
        toastObject.message = custom.message;
        toastObject.title = custom && custom.title ? custom.title : DEFAULT_TITLE_FOR_WARNING;
        toastObject.timeOut = custom && custom.timeOut ? custom.timeOut : DEFAULT_TIME_OUT;
        this.toastr.info(toastObject.message, toastObject.title, {
            timeOut: toastObject.timeOut
        });
    }
}
