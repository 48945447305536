import { HttpClient } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';



@Injectable({
    providedIn: 'root'
})


export class BookRepository implements OnInit {
    public books = new BehaviorSubject<any>([]);
    private baseUrl = 'https://xyz.icodash.eu/api/';
    constructor(
        private http: HttpClient
    ) {
    }

    getCollection(options: any): void {
        this.http.get(this.baseUrl + 'books').subscribe(res => {
            this.books.next(res['hydra:member']);
        });
    }

    getItem(): void {

    }

    updateItem(): void {

    }

    createItem(): void {

    }

    ngOnInit(): void {

    }
}