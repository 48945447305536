import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDialogModule, MatChipsModule, MatButtonModule, MatIconModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatRadioModule, MatAutocompleteModule, MatSelectModule, MatSlideToggleModule, MatCardModule, MatBadgeModule, MatStepperModule, MatDividerModule, MatTableModule, MatButtonToggleModule, MatListModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatTooltip, MatTooltipModule } from '@angular/material';
import {  SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [],
  imports: [
    MatMomentDateModule,
    MatButtonModule, 
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatSlideToggleModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatCardModule,
    ScrollingModule,
    MatBadgeModule,
    MatStepperModule,
    MatDividerModule,
    MatChipsModule,
    MatTableModule,
    DragDropModule,
    MatButtonToggleModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  exports: [
    MatMomentDateModule,
    MatButtonModule, 
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatSlideToggleModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatCardModule,
    ScrollingModule,
    MatBadgeModule,
    MatStepperModule,
    MatDividerModule,
    MatChipsModule,
    MatTableModule,
    DragDropModule, 
    MatButtonToggleModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ]
})
export class MaterialModule { }
