export const model = {
    title: 'Books',
    create: {
        name: {
            type: 'string',
            validators: [{ name: 'required' }, { name: 'minLength', value: 2 }],
            default: 'test cu default'
        },
        surname: 'surname',
        age: {
            type: 'number'
        },
        description: {
            type: 'text',
            label: 'Descriere',
            validators: [{ name: 'required' }, { name: 'minLength', value: 2 }]

        },
        email: {
            type: 'string',
            validators: [{ name: 'required' }, { name: 'email' }]
        },
        isJuridical: {
            type: 'checkbox',
            label: 'Is juridical',
            default: true
        },
        startDate: {
            type: 'date',
            label: 'Date',
            default: '1995-12-18'
        },
        ocupations: {
            type: 'radioButton',
            options: [{ label: 'test' }, { label: 'test 2', default: true }]
        },
        autocompletare: {
            type: 'autocomplete',
            options: [{ label: 'Abc' }, { label: 'dEf' }]
        },
        selectare: {
            type: 'select',
            options: [{ label: 'Abc' }, { label: 'dEf', default: true }]
        },
        glisor: {
            type: 'slider',
            default: true
        }
    }
};
