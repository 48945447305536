import { Injectable } from '@angular/core';
import * as deepstream from 'deepstream.io-client-js'
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DsService {

    private ds;
    public client;

    constructor(private router: Router) {
        this.client = deepstream(environment.deepStreamURL);

        this.client.on('error', ( error, event, topic ) => {
            console.log('Deepstream server unavailable, make sure the server is working or contact suport 🔧')
        })
        
    }

    login(credentials) {
        return new Promise((resolve,reject) =>{
            this.client.login({ token: credentials }, (success, data) => {
                if (success) {
                    resolve(data);
                } else {
                    // this.router.navigate(['/login']);
                    reject(data);
                }
            });
        })
       
    }

    getRecord(name) {
        return this.ds.record.getRecord(name);
    }

    getList(name) {
        return this.ds.record.getList(name);
    }
}
