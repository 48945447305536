export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'ACTIONS':'What do you want to do?',
            'CRORG' : {
                'TITLE': 'Create Organisation',
                'FWD': {
                    'TITLE': 'Forwarder'
                },
                'SHP': {
                    'TITLE': 'Shipper'
                },
                'CAR': {
                    'TITLE': 'Carrier'
                },
            },
            'CRUSR' : {
                'TITLE': 'Create User',
            },
            'CRDRV' : {
                'TITLE': 'Create Driver',
            },
            'CRTRK' : {
                'TITLE': 'Create Car',
            },
            'CRDPT' : {
                'TITLE': 'Create Department',
            },
            'CRORD' : {
                'TITLE': 'Create Order',
            },
            'CRRECORD' : {
                'TITLE': 'Create Recurring Order',
            },
            'APPLICATIONS': 'Applications',
            'SAMPLE': {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'SHIPPER': {
                'TITLE': 'Shipper',
                'BADGE': '2'
            },
            'CONFIGS': {
                'TITLE': 'Configs',
                'LIST': {
                    'TITLE': 'Configs List',
                },
                'PROFILES': {
                    'TITLE': 'Truck profiles'
                }
            },
            'CARRIER': {
                'TITLE': 'Carrier'
            },
            'FORWARDER': {
                'TITLE': 'Forwarder'
            },
            'TRUCK': {
                'TITLE': 'Truck'
            },
            'DRIVER': {
                'TITLE': 'Driver'
            },
            'USER': {
                'TITLE': 'User'
            },
            'ORDER': {
                'TITLE': 'Order'
            },
            'RECURRING_ORDER': {
                'TITLE': 'Recurring Order'
            },
            'SAVED_ORDER': {
                'TITLE': 'Saved Orders'
            }
        }
    }
};
