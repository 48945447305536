import { State, Action, StateContext } from '@ngxs/store';
import { SetNotificationsCounter, IncreaseNotificationsCounter, ResetNotificationsCounter, AddNotification } from './app.actions';
import { NotificationService } from '../notification/notification.service';
import { tap } from 'rxjs/operators';

export interface NotificationsStateStructure {
    counter: number;
    lastNotification: any;
}

@State<NotificationsStateStructure>({
    name: 'notifications',
    defaults: {
        counter: 0,
        lastNotification: null
    }
})
export class NotificationsState {

    constructor(private notificationService: NotificationService) { }

    @Action(SetNotificationsCounter)
    setNotificationsCounter({ patchState }: StateContext<NotificationsStateStructure>, { payload }: SetNotificationsCounter) {
        patchState({ counter: payload, lastNotification: null });
    }

    @Action(IncreaseNotificationsCounter)
    increaseNotificationsCounter({ patchState, getState }: StateContext<NotificationsStateStructure>) {
        const currentNotificationCounter = getState().counter;

        patchState({ counter: currentNotificationCounter + 1, lastNotification: null });
    }

    @Action(AddNotification)
    addNotification({ patchState, getState }: StateContext<NotificationsStateStructure>, { notification }: AddNotification) {
        const currentNotificationCounter = getState().counter;

        patchState({ counter: currentNotificationCounter + 1, lastNotification: notification });
    }

    @Action(ResetNotificationsCounter, { cancelUncompleted: true })
    resetNotificationsCounter({ patchState }: StateContext<NotificationsStateStructure>) {

        return this.notificationService.markAllAsRead()
            .pipe(
                tap(success => {
                    if (success) {
                        patchState({ counter: 0, lastNotification: null });
                    }
                })
            )

    }
}