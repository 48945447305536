import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UtilsService } from '../../../main/shared/utils/utils.service';
import { environment } from 'environments/environment';

import { Store, Select } from '@ngxs/store';
import { IncreaseNotificationsCounter, ResetNotificationsCounter, AddNotification } from '../../../main/shared/state/app.actions';
import { Router } from '@angular/router';
import { DsService } from 'app/main/shared/ds/ds.service';
import { NotificationsState } from 'app/main/shared/state/notification.state';
import { UserState } from 'app/main/shared/state/user.state';
import {NavigationService} from '../../../navigation/navigation.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    @Select(NotificationsState) notifications$;
    @Select(UserState) currentUser$;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    meData: any = null;
    notificationCounter = null;
    client = null;

    public logoutUrl = `${environment.baseUrl}api/logout`;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private utilsService: UtilsService,
        private store: Store,
        private router: Router,
        private ds: DsService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = [];

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    increaseCounter() {
        this.store.dispatch([
            new AddNotification({ name: 'Test Notification' })
        ]);
    }

    logOut() {
        this.router.navigate(['/login']);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.currentUser$.subscribe((me: any) => {
            if(me) {
                this.meData = me;
                this.navigation = NavigationService.getNavigation(this.meData.type, this.meData.roles);
                this.utilsService.getToken().subscribe((token: any) => {
                    this.ds.login(token)
                        .then(response => {
                            this.ds.client.event.subscribe('client_' + me.type + "_" + me.id, response => {
                                this.store.dispatch([
                                    new AddNotification(JSON.parse(response.message))
                                ]);
                            })
                    })
                    .catch(e => console.log('Fix this error'));
                })
            }
        });
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });

        this.notifications$
            .subscribe(response => {
                this.notificationCounter = response.counter;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();

        if (key === 'quickPanel')
            this.store.dispatch([ResetNotificationsCounter]);
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
