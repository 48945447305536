import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  readonly baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  public getUserId(){
    return this.http.get(`${this.baseUrl}api/me`, { withCredentials: true })
  }
  public getAllNotifications(id: string) {
    return this.http.get(`${this.baseUrl}api/notifications?user.id=${id}&order[createdAt]=desc`, { withCredentials: true });
  }

  public markAllAsRead() {
    return this.http.get(`${this.baseUrl}api/multiple_notifications`, {withCredentials: true});
  }

  public updateNotification(id, entity) {
    return this.http.put(`${this.baseUrl}api/notifications/${id}`, entity, {withCredentials: true} );
  }

}
