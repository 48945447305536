// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    baseUrl   : 'https://one-api.nordstar-logistik.com/',
    // baseUrl: 'http://localhost:8000/',
    // baseUrl: 'https://c417e81f.ngrok.io/',
    deepStreamURL : 'wss://one-ds.nordstar-logistik.com',
    // deepStreamURL: 'localhost:6020',
    loadOptimisationURL: 'https://one-renderer.nordstar-logistik.com/?baseUrl='
    // loadOptimisationURL: 'http://127.0.0.1:8081?baseUrl='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
