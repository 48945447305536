import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment} from '../../../../environments/environment';
import { ErrorHandlerService } from '../../shared/error-handler/error-handler.service';
import { includes } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private readonly baseUrl: string = environment.baseUrl;
  private me = null;
  private token = null;

  private constructor(
    private errorHandler: ErrorHandlerService,
    private http: HttpClient
  ) { }

  public getMe(): Observable<any> {
    return new Observable(observer => {
      if(this.me){
        observer.next(this.me);
      }
      else{
        // this.populate2();
        this.populate().subscribe(res =>{
          this.me = res;
          observer.next(this.me );
        },
        err =>{
          this.errorHandler.handleHttp(err);
        })
      }
    });
  }

  public getToken(): Observable<any> {
    return new Observable(observer => {
      if(this.token){
        observer.next(this.token);
      }
      else{
        // this.populate2();
        this.populateToken().subscribe(res =>{
          this.token = res;
          observer.next(this.token);
        },
        err =>{
          this.errorHandler.handleHttp(err);
        })
      }
    });
  }

  public normalizeQueryFilters(self, filters?) {
    let filter = '?';

    if (self.roleQuerry) {
      filter += self.roleQuerry;
    }

    if (filters) {
      filter += `&${filters}`;
    }

    return filter;
  }

  public normalizeLabel(data) {
    if(data.displayName) {
      return data.displayName;
    } else if(typeof data.label !== 'string') {
      return `${data.label.displayName} (${parseFloat(data.height)}cm x ${parseFloat(data.width)}cm x ${parseFloat(data.length)}cm x ${parseFloat(data.weight).toFixed(2)}kg)`
    } else { 
      return `${data.label} (${parseFloat(data.height)}cm x ${parseFloat(data.width)}cm x ${parseFloat(data.length)}cm x ${parseFloat(data.weight).toFixed(2)}kg)`
    }
  }
  
  public arePackagesEqual(firstPackage, secondPackage) {
    let equal = true;

    for( let key in firstPackage ) {
      if(['label', 'height', 'length', 'stackable', 'tiltable', 'weight', 'width'].includes(key)) {
        if(firstPackage[key] && secondPackage[key] && firstPackage[key] === secondPackage[key])
          equal = false;
      }
    }

    return equal;
  }

  private populate(){
    return this.http.get(`${this.baseUrl}api/me`, { withCredentials: true });
  }

  private populateToken(){
    return this.http.get(`${this.baseUrl}api/get_token`, { withCredentials: true });
  }
  
  public getSuggestions(path: string, entity: string, value: string, size: number = null) {
    if(size) {
      return this.http.get(`${this.baseUrl}${path}?string=${value}&entity=${entity}&size=${size}`, { withCredentials: true });
    }
    return this.http.get(`${this.baseUrl}${path}?string=${value}&entity=${entity}`, { withCredentials: true });
  }


  private populate2(){
    this.http.get(`${this.baseUrl}api/users?shipper.id=1`, { withCredentials: true }).subscribe(res=>{
    });
  }

  public getContractPaymentTypes(){
    return this.http.get(`${this.baseUrl}api/contract_payment_types`,  { withCredentials: true });
  }

  public getTollPaymentTypes(){
    return this.http.get(`${this.baseUrl}api/toll_payment_types`,  { withCredentials: true });
  }

  public validateCar(payload) {
    return this.http.post(`${this.baseUrl}api/validate-car`, payload, { withCredentials: true });
  }
}
