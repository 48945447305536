import { State, Action, StateContext } from '@ngxs/store';
import { SetUserData } from './app.actions';

export interface UserStateStructure {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    forwarder: number;
    type: string;
}

@State<UserStateStructure>({
    name: 'currentUser',
    defaults: null
})
export class UserState {

    @Action(SetUserData)
    setUserData({ patchState }: StateContext<UserStateStructure>, { user }: SetUserData) {
        patchState({ ...user });
    }
}