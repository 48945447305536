export const NavigationConstantsActions = {
    createOrganisation: {
        id: 'create-organization',
        title: 'Create Organization',
        translate: 'NAV.CRORG.TITLE',
        type: 'collapsable',
        icon: 'looks_one',
        children: [
            {
                id: 'forwarder-create',
                title: 'Forwarder',
                type: 'item',
                translate: 'NAV.CRORG.FWD.TITLE',
                url: '/forwarder'
            },
            {
                id: 'shipper-create',
                title: 'Shipper',
                type: 'item',
                translate: 'NAV.CRORG.SHP.TITLE',
                url: '/shipper'
            },
            {
                id: 'carrier-create',
                title: 'Carrier',
                type: 'item',
                translate: 'NAV.CRORG.CAR.TITLE',
                url: '/carrier'
            }
        ]
    },
    createUser: {
        id: 'create-user',
        type: 'item',
        title: 'Create User',
        translate: 'NAV.CRUSR.TITLE',
        url: '/user',
        icon: 'looks_two',
    },
    createDriver: {
        id: 'create-driver',
        type: 'item',
        title: 'Create Driver',
        translate: 'NAV.CRDRV.TITLE',
        url: '/driver',
        icon: 'looks_3',
    },
    createTruck: {
        id: 'create-truck',
        type: 'item',
        title: 'Create Truck',
        translate: 'NAV.CRTRK.TITLE',
        url: '/car',
        icon: 'looks_4',
    },
    createDepartment: {
        id: 'create-department',
        type: 'item',
        title: 'Create Department',
        translate: 'NAV.CRDPT.TITLE',
        url: '/department',
        icon: 'looks_5',
    },
    createOrder: {
        id: 'create-order',
        type: 'item',
        title: 'Create Order',
        translate: 'NAV.CRORD.TITLE',
        url: '/order',
        icon: 'looks_6',
    }
}


export const NavigationConstantsApplications = {
    shipper: {
        id: 'shipper',
        type: 'item',
        title: 'Shipper',
        translate: 'NAV.SHIPPER.TITLE',
        url: '/shipper/list',
        icon: 'business_center',
    },
    carrier: {
        type: 'item',
        url: '/carrier/list',
        id: 'carrier',
        title: 'Carrier',
        translate: 'NAV.CARRIER.TITLE',
        icon: 'commute',
    },
    forwarder: {
        type: 'item',
        url: '/forwarder/list',
        id: 'forwarder',
        title: 'Forwarder',
        translate: 'NAV.FORWARDER.TITLE',
        icon: 'arrow_forward',
    },
    car: {
        type: 'item',
        url: '/car/list',
        id: 'truck',
        title: 'Truck',
        translate: 'NAV.TRUCK.TITLE',
        icon: 'local_shipping'
    },
    driver: {
        type: 'item',
        url: '/driver/list',
        id: 'driver',
        title: 'Driver',
        translate: 'NAV.DRIVER.TITLE',
        icon: 'airline_seat_recline_normal'
    },
    creditNotes: {
        type: 'item',
        url: '/creditNotes/credit-notes-list',
        id: 'user',
        title: 'Credit Notes',
        translate: 'NAV.CREDIT_NOTES.TITLE',
        icon: 'save'
    },
    user: {
        type: 'item',
        url: '/user/list',
        id: 'user',
        title: 'User',
        translate: 'NAV.USER.TITLE',
        icon: 'account_box'
    },
    department: {
        type: 'item',
        url: '/department/list',
        id: 'user',
        title: 'Department',
        translate: 'NAV.USER.TITLE',
        icon: 'reorder'
    },
    order: {
        type: 'item',
        url: '/order/list',
        id: 'order',
        title: 'Order',
        translate: 'NAV.ORDER.TITLE',
        icon: 'business_center'
    },
    recurringOrder: {
        id: 'recurring-order',
        type: 'item',
        title: 'Recurring Order',
        translate: 'NAV.RECURRING_ORDER.TITLE',
        url: '/order/recurrences-list',
        icon: 'repeat',
    },
    savedOrder: {
        id: 'saved-order',
        type: 'item',
        title: 'Saved Orders',
        translate: 'NAV.SAVED_ORDER.TITLE',
        url: '/order/saved-orders-list',
        icon: 'save',
    },
    configs: {
        id: 'configs',
        title: 'Configs',
        translate: 'NAV.CONFIGS.TITLE',
        type: 'collapsable',
        icon: 'settings',
        children: [
            {
                id: 'config-list',
                title: 'Configs List',
                translate: 'NAV.CONFIGS.LIST.TITLE',
                type: 'item',
                url: '/configs/list'
            },
            {
                id: 'truck-profiles',
                title: 'Truck profiles',
                translate: 'NAV.CONFIGS.PROFILES.TITLE',
                type: 'item',
                url: '/configs/car-profiles'
            }
        ],
    },
    invoicing: {
        id: 'invoicing',
        title: 'Invoicing',
        type: 'collapsable',
        icon: 'attach_money',
        children: [
            {
                id: 'invoice-list',
                title: 'Invoices',
                type: 'item',
                url: '/invoicing/invoices'
            },
            {
                id: 'credit-notes-list',
                title: 'Credit Notes',
                type: 'item',
                url: '/invoicing/credit-notes'
            },
            {
                id: 'invoice-series',
                title: 'Series',
                type: 'item',
                url: '/invoicing/series'
            }
        ]
    }
};
