import { FuseNavigation } from '@fuse/types';
import { NavigationConstantsActions } from './navigation-constants'
import { NavigationConstantsApplications } from './navigation-constants'

export const navigation: FuseNavigation[] = [
    {
        id: 'actions',
        title: 'What do you want to do?',
        translate: 'NAV.ACTIONS',
        type: 'group',
        children: [
            NavigationConstantsActions.createOrganisation as FuseNavigation,
            NavigationConstantsActions.createUser as FuseNavigation,
            NavigationConstantsActions.createDriver as FuseNavigation,
            NavigationConstantsActions.createTruck as FuseNavigation,
            NavigationConstantsActions.createDepartment as FuseNavigation,
            NavigationConstantsActions.createOrder as FuseNavigation,
        ]
    },
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            NavigationConstantsApplications.forwarder as FuseNavigation,
            NavigationConstantsApplications.shipper as FuseNavigation,
            NavigationConstantsApplications.carrier as FuseNavigation,
            NavigationConstantsApplications.user as FuseNavigation,
            NavigationConstantsApplications.car as FuseNavigation,
            NavigationConstantsApplications.driver as FuseNavigation,
            NavigationConstantsApplications.configs as FuseNavigation,
            NavigationConstantsApplications.department as FuseNavigation,
            NavigationConstantsApplications.order as FuseNavigation,
            NavigationConstantsApplications.recurringOrder as FuseNavigation,
            NavigationConstantsApplications.savedOrder as FuseNavigation,
            NavigationConstantsApplications.invoicing as FuseNavigation,
        ]
    }
];
