import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForwarderRequestService {

  readonly baseUrl: string = environment.baseUrl;


  constructor(
    private http: HttpClient
  ) { }

  public getForwarderRequrst(id: string){
    return this.http.get(`${this.baseUrl}api/forwarder_requests/${id}`, { withCredentials: true })
  }

  /**
   * markAsAccepted
   */
  public markAsAccepted(id: string) {
    const entity = {
      status: 'accepted'
    }
    return this.http.put(`${this.baseUrl}api/forwarder_requests/${id}`, entity, { withCredentials: true })    
  }

}
