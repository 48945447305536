import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from 'app/main/shared/material/material.module';
import { FuseSharedModule } from '@fuse/shared.module';

const routes = [
  {
      path: 'login',
      component: LoginComponent
  }
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MaterialModule
  ]
})
export class AuthenticateModule { }
