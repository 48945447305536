import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { CustomToastrService } from 'app/main/shared/toastr/toastr.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loginUrl = `${environment.baseUrl}auth/login`;

  constructor( private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private cToast: CustomToastrService) { 
      
    this._fuseConfigService.config = {
      layout: {
          navbar: {
              hidden: true
          },
          toolbar: {
              hidden: true
          },
          footer: {
              hidden: true
          }
      }
  };
  }

  goToPage(param){
    
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if(queryParams['error']) {
        switch(queryParams['error']){
          case 'bad_credentials': this.cToast.err({ message: 'Bad Credentials' }); break;
          case 'bad_request' : this.cToast.err({message : 'Server error! Please try again'}); break;
          default : this.cToast.err({message: 'Something went wrong! Please try again'});
        }
      }
    })
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
  });
  }

}
