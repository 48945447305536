import { Injectable } from '@angular/core';
import {NavigationConstantsActions, NavigationConstantsApplications} from './navigation-constants';
import {FuseNavigation} from '../../@fuse/types';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() { }

  public static getNavigation(userType: string, roles: Array<string>)
  {
    let actions = [];
    let applications = [];

    if ((userType == 'forwarder' && ['ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))) {
        actions.push(NavigationConstantsActions.createOrganisation as FuseNavigation);
    }

    if ((userType == 'forwarder' && ['ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))) {
        applications.push(NavigationConstantsApplications.forwarder as FuseNavigation);
    }

    if ((userType == 'forwarder' && ['ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
        || (userType == 'shipper' && ['ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
    ) {
      applications.push(NavigationConstantsApplications.shipper as FuseNavigation);
    }

    if ((userType == 'forwarder' && ['ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
      || (userType == 'carrier' && ['ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
    ) {
      applications.push(NavigationConstantsApplications.carrier as FuseNavigation);
    }

    if (
      (userType == 'shipper' && ['ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
      || (userType == 'carrier' && ['ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
      || (userType == 'forwarder' && ['ROLE_ADMIN', 'ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
    ) {
        applications.push(NavigationConstantsApplications.user as FuseNavigation);
        actions.push(NavigationConstantsActions.createUser as FuseNavigation);
    }

    if ((userType == 'carrier' && ['ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
      || (userType == 'forwarder' && ['ROLE_ADMIN', 'ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
    ) {
        applications.push(NavigationConstantsApplications.car as FuseNavigation);
        applications.push(NavigationConstantsApplications.driver as FuseNavigation);
        actions.push(NavigationConstantsActions.createDriver as FuseNavigation);
        actions.push(NavigationConstantsActions.createTruck as FuseNavigation);
    }

    if ((userType == 'forwarder' && ['ROLE_PLATFORM_ADMIN'].some(role => roles.includes(role)))) {
        applications.push(NavigationConstantsApplications.configs as FuseNavigation);
    }

    if (
      (userType == 'shipper' && ['ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
      || (userType == 'forwarder' && ['ROLE_ADMIN', 'ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
    ) {
        applications.push(NavigationConstantsApplications.department as FuseNavigation);
        actions.push(NavigationConstantsActions.createDepartment as FuseNavigation);
    }

    if (
      (userType == 'shipper' && ['ROLE_PLANNER', 'ROLE_SECTION_MANAGER'].some(role => roles.includes(role)) )
      || (userType == 'carrier' && ['ROLE_PLANNER'].some(role => roles.includes(role)))
      || (userType == 'forwarder' && ['ROLE_PLANNER', 'ROLE_SECTION_MANAGER', 'ROLE_ODI_ANALYST', 'ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
    ) {
        applications.push(NavigationConstantsApplications.order as FuseNavigation);
        applications.push(NavigationConstantsApplications.recurringOrder as FuseNavigation);
        applications.push(NavigationConstantsApplications.savedOrder as FuseNavigation);
        actions.push(NavigationConstantsActions.createOrder as FuseNavigation);
    }

    if (
      (userType == 'shipper' && ['ROLE_TRAFFIC_MANAGER', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)) )
      || (userType == 'carrier' && ['ROLE_PLANNER'].some(role => roles.includes(role)))
      || (userType == 'forwarder' && ['ROLE_PLATFORM_MANAGER', 'ROLE_PLATFORM_ADMIN', 'ROLE_SUPER_ADMIN'].some(role => roles.includes(role)))
    ) {
      applications.push(NavigationConstantsApplications.invoicing as FuseNavigation);
    }

    return [
        {
            id: 'actions',
            title: 'What do you want to do?',
            translate: 'NAV.ACTIONS',
            type: 'group',
            children: actions
        },
        {
            id: 'applications',
            title: 'Applications',
            translate: 'NAV.APPLICATIONS',
            type: 'group',
            children: applications
        }
    ] as FuseNavigation[];
  }
}
