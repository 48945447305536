import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomToastrService } from '../toastr/toastr.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    private constructor(
        private router: Router,
        private cToast: CustomToastrService
    ) { }

    public handleHttp(err: any, showToast = true): any {
        console.log(err);

        if (err.status === 401) {
            this.router.navigate(['/login']);
        }

        if (showToast) {
            if (err.error) {
                if (err.error['hydra:description']) {
                    this.cToast.err({
                        message: err.error['hydra:description'],
                        title: err.error['hydra:title']
                    });
                } else if (err.error.detail) {
                    this.cToast.err({ message: err.error.detail });
                } else if (err.error.message) {
                    this.cToast.err({ message: err.error.message });
                } else if (err.error.error) {
                    this.cToast.err({message: err.error.error});
                } else if (typeof err.error === 'string') {
                    this.cToast.err({ message: err.error });
                } else {
                    this.cToast.err({ message: JSON.stringify(err.error) });
                }
            } else {
                if (err.message) {
                    this.cToast.err({ message: err.message });
                } else {
                    this.cToast.err({ message: JSON.stringify(err.error) });
                }
            }
        }
    }
}
