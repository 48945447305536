import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Store, Select } from '@ngxs/store';
import { SetNotificationsCounter } from 'app/main/shared/state/app.actions';
import { NotificationService } from 'app/main/shared/notification/notification.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
// import { ForwarderRequestService } from '../../../shared/forwarder-request/forwarder-request.service';
import { ForwarderRequestService } from '../../../main/shared/forwarder-request/forwarder-request.service';
import { CustomToastrService } from '../../../main/shared/toastr/toastr.service';
import * as moment from 'moment';
import { NotificationsState } from 'app/main/shared/state/notification.state';

const STATUS_ACCEPTED = 'accepted';
const STATUS_PENDING  = 'pending';
const STATUS_DECLINED = 'declined';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
    @Select(NotificationsState) notifications$;
    date: Date;
    events: any[];
    notes: any[];
    icon: String = 'warning';
    notifications: any[] = [
        {
            title: 'Shipping',
            body: 'The owls are not what they seem',
            icon: 'warning'
        },
        {
            title: 'Carrier',
            body: 'The owls are not what they seem',
            icon: 'room'
        },
        {
            title: 'Shipping',
            body: 'The owls are not what they seem',
            icon: 'done'
        },
    ];
    settings: any;

    /**
     * Constructor
     */
    constructor(
        private router: Router,
        private http: HttpClient,
        private store: Store,
        private notificationService: NotificationService,
        private forwarderRequestService: ForwarderRequestService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private cTostr: CustomToastrService

    ) {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true
        };
    }
    triggerNotification(ev: any) {
        // this.router.navigate([`/${ev.targetEntity.toLowerCase()}/${ev.targetPage}`]);
        const timeNow = moment();
        if(!ev.typeId){
            if(!ev.clickedAt){
                ev.clickedAt = timeNow.format();
                this.notificationService.updateNotification(ev.id, {clickedAt: ev.clickedAt}).subscribe( notif => {});
            }
            this.router.navigateByUrl(ev.targetPage).then(promise => {  
                this._fuseSidebarService.getSidebar('quickPanel').close();
            });
        }
        else {
            this.forwarderRequestService.getForwarderRequrst(ev.typeId).subscribe((forwarderRequest: any) =>{
                if(STATUS_ACCEPTED == forwarderRequest.status){
                    this.cTostr.info({message: 'Already treated this request'})
                    if(!ev.clickedAt){
                        ev.clickedAt = timeNow.toISOString();
                        this.notificationService.updateNotification(ev.id, {clickedAt: ev.clickedAt}).subscribe( notif => {});
                    }
                }
                else{
                    if(!ev.clickedAt){
                        const timeNow = new Date();
                        ev.clickedAt = timeNow.toISOString();
                        this.notificationService.updateNotification(ev.id, {clickedAt: ev.clickedAt}).subscribe( notif => {
                            this.router.navigate([`/${ev.targetEntity.toLowerCase()}/${ev.targetPage}`], { queryParams: { forwarderRequest: ev.typeId }});
                        });
                    }
                    else{
                        this.router.navigate([`/${ev.targetEntity.toLowerCase()}/${ev.targetPage}`], { queryParams: { forwarderRequest: ev.typeId }});
                    }
                    this._fuseSidebarService.getSidebar('quickPanel').toggleOpen();
                }
            }, err =>{
                console.log('fail get forwarder request');
            })
        }
    }

    softDeleteNotification(notification: any) {
        notification.active = false;
        this.notificationService.updateNotification(notification.id, {active: false}).subscribe( notif => {});
    }

    ngOnInit() {
        this.notificationService.getUserId().subscribe( (user: any) => {
            this.notificationService.getAllNotifications(user.id)
                .subscribe((notifications: any) => {
                    this.notifications = notifications['hydra:member'];
                const unseenNotificationsCounter = this.notifications.reduce((accumulator, current: any) => current.seenAt ? accumulator : accumulator + 1, 0);
                this.store.dispatch([
                    new SetNotificationsCounter(unseenNotificationsCounter)
                ])
            });
    });

    this.notifications$
        .subscribe(notifications => {
            if(null !== notifications.lastNotification) {
                this.notifications.unshift(notifications.lastNotification);
                this.notifications = this.notifications.slice();
            }
            if(this.notifications.length === 1 && Object.keys(this.notifications[0]).length === 0){
                this.notifications.shift()
            }
        })
    }
}               
