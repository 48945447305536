import { Component, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BookRepository } from '../../repositories/bookRepository'
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment, Moment } from 'moment';

const moment = _moment;

import { model } from './model';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { CustomToastrService } from '../shared/toastr/toastr.service'


@Component({
    selector: 'sample',
    templateUrl: './sample.component.html',
    styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
    formGroup: FormGroup;
    model = model;
    validSubmit: boolean = false;
    filteredOptions: string[];

    /**
     * Constructor
     *
     * @param _fuseTranslationLoaderService
     * @param bookRepository
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private bookRepository: BookRepository,
        private fb: FormBuilder,
        private cToastr: CustomToastrService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        bookRepository.getCollection({});

        this.formGroup = this.fb.group(this.parseModelToFbData())
        // console.log(model);
    }
    cfnc(ev) {
        console.log("reeee", { ev })
    }
    objKeys(obj): any {
        return Object.keys(obj);
    }
    getPlaceholder(key): string {
        if ('string' == typeof this.model.create[key] || (
            'object' == typeof this.model.create[key] &&
            'undefined' == typeof this.model.create[key].label)) {
            return key.charAt(0).toUpperCase() + key.slice(1);
        }
        else if ('object' == typeof this.model.create[key]) {
            return this.model.create[key].label
        }
    }
    cLog(): void {
        console.log(this.formGroup.value);
    }

    parseModelToFbData(): any {
        let fbData = {};
        const keyArr = Object.keys(this.model.create);
        keyArr.forEach(key => {
            let validationArray = [];
            if ('undefined' != typeof this.model.create[key].validators && 0 < this.model.create[key].validators.length) {
                this.model.create[key].validators.forEach(validation => {
                    validation.value ? validationArray.push(Validators[validation.name](validation.value)) : validationArray.push(Validators[validation.name]);
                })
            }
            fbData[key] = new FormControl(null, validationArray)

            fbData[key].setValue(this.getDefaultValue(key));
        })
        return fbData;
    }
    getDefaultValue(key): any {
        if ('date' == this.model.create[key].type && this.model.create[key].default) {
            return moment(this.model.create[key].default);
        } else if ('radioButton' == this.model.create[key].type) {
            return this.model.create[key].options.find(elem => elem.default == true)
        } else if ('select' == this.model.create[key].type) {
            return this.model.create[key].options.find(elem => elem.default == true)
        } else {
            return this.model.create[key].default;
        }
    }

    autoCompleteSearch(key) {
        const filterValue = this.formGroup.controls[key].value.toString().toLowerCase();
        this.filteredOptions = this.model.create[key].options.filter(option => option.label.toLowerCase().includes(filterValue));
    }

    selectedFilter(event) {
        console.log(event);
    }

    createEntity(event) {
        console.log(event);
    }

    footerEvent() {
        this.cToastr.success({message:'and message', title:'with title'});
        this.cToastr.success({message:'with message'});
        this.cToastr.success({title:'with title'});
        this.cToastr.success();

        this.cToastr.err({message:'and message', title:'with title'});
        this.cToastr.err({message:'with message'});
        this.cToastr.err({title:'with title'});
        this.cToastr.err();
    }

    customTest(event) {
        console.log(event)
    }


    ngOnInit(): void {
        this.bookRepository.books.subscribe(books => {
            if (0 != books.length) {
                console.log(books);
            }
        });
        // this.bookRepository.getCollection({});
    }

}
