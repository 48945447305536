import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { ToastrModule } from 'ngx-toastr';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { MaterialModule } from './main/shared/material/material.module';
import { AuthenticateModule } from './main/authenticate/authenticate.module';

// Store
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NotificationsState } from './main/shared/state/notification.state';
import { UserState } from './main/shared/state/user.state';
import { ClipboardModule } from 'ngx-clipboard';
import {ChangeForwarderModalComponent} from './main/shared/change-forwarder-modal/change-forwarder-modal.component';



const routes: Routes = [
    { path: 'shipper', loadChildren: './main/crm/shipper/shipper.module#ShipperModule' },
    { path: 'carrier', loadChildren: './main/crm/carrier/carrier.module#CarrierModule' },
    { path: 'forwarder', loadChildren: './main/crm/forwarder/forwarder.module#ForwarderModule' },
    { path: 'car', loadChildren: './main/crm/car/car.module#CarModule' },
    { path: 'driver', loadChildren: './main/crm/driver/driver.module#DriverModule' },
    { path: 'configs', loadChildren: './main/crm/configs/configs.module#ConfigsModule' },
    { path: 'login', loadChildren: './main/authenticate/authenticate.module#AuthenticateModule' },
    { path: 'user', loadChildren: './main/crm/user/user.module#UserModule' },
    { path: 'order', loadChildren: './main/orders/orders.module#OrdersModule' },
    { path: 'department', loadChildren: './main/departments/departments.module#DepartmentsModule' },
    { path: 'invoicing', loadChildren: './main/invoicing/invoicing.module#InvoicingModule' },
    { path: '**', redirectTo: 'user/list' }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),

        TranslateModule.forRoot(),

        MaterialModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: false,
        }),

        // App modules
        LayoutModule,
        SampleModule,
        AuthenticateModule,

        // Store
        NgxsModule.forRoot([
            NotificationsState,
            UserState
        ]),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({ disabled: true }),
        ClipboardModule,

    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
