export class SetNotificationsCounter {
    static readonly type = 'SET_NOTIFICATIONS_COUNTER';

    constructor(public payload: number) { }
}

export class IncreaseNotificationsCounter {
    static readonly type = 'INCREASE_NOTIFICATIONS_COUNTER';

    constructor() { }
}

export class ResetNotificationsCounter {
    static readonly type = 'RESET_NOTIFICATIONS_COUNTER';

    constructor() { }
}

export class AddNotification {
    static readonly type = 'ADD_NOTIFICATION';

    constructor(public notification: any) { }
}

export class SetUserData {
    static readonly type = 'SET_USER_DATA';

    constructor(public user: any) { }
}